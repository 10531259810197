import useWindowSize from 'hooks/useWindowResize';
import { FC, useEffect, useRef } from 'react';
import isInViewport from 'utils/isInViewport';
import MainNav2 from './MainNav2';

export interface HeaderProps {
  navType?: 'MainNav1' | 'MainNav2';
  className?: string;
}

const Header: FC<HeaderProps> = ({ navType = 'MainNav1', className = '' }) => {
  const headerRef = useRef<HTMLDivElement>(null);
  let WIN_PREV_POSITION = window.pageYOffset;

  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== 'undefined') {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(showHideHeader);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showHideHeader = () => {
    // if (!headerRef.current || WIN_WIDTH >= 767) return; // Only apply the logic if width < 768

    if (!headerRef.current) return; // Only apply the logic if width < 768

    const currentScrollPos = window.pageYOffset;

    // Check if the header should be hidden or shown
    if (currentScrollPos > WIN_PREV_POSITION) {
      // Scrolling down
      if (
        isInViewport(headerRef.current) &&
        currentScrollPos - WIN_PREV_POSITION < 80
      ) {
        return; // Do nothing if in viewport and scroll is less than 80px
      }
      headerRef.current.classList.add('Header--hide');
    } else {
      // Scrolling up
      if (
        !isInViewport(headerRef.current) &&
        WIN_PREV_POSITION - currentScrollPos < 80
      ) {
        return; // Do nothing if not in viewport and scroll is less than 80px
      }
      headerRef.current.classList.remove('Header--hide');
    }

    WIN_PREV_POSITION = currentScrollPos; // Update previous position
  };

  return (
    <div
      ref={headerRef}
      className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}
    >
      <MainNav2 />{' '}
    </div>
  );
};

export default Header;
