// atoms.ts
import { atom, useAtom } from 'jotai';

export const exchangeRatesAtom = atom<{
  KRW_TO_USD: number;
  KRW_TO_CAD: number;
} | null>(null);

export const useExchangeRatesAtom = () => {
  const exchangeRatesState = useAtom(exchangeRatesAtom);
  return exchangeRatesState;
};
