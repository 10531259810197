// atoms.ts
import { atom } from 'jotai';
import { Package } from 'type/interface'; // Ensure LocationNetwork is imported

// Initial empty state for the plans array
const initialPlansState: Package[] = [];

// Initial empty state for the picked plan
const initialPickedPlanState: Package = {
  packageCode: '',
  slug: '',
  name: '',
  price: 0,
  currencyCode: '',
  volume: 0,
  smsStatus: 0,
  dataType: 0,
  unusedValidTime: 0,
  duration: 0,
  durationUnit: '',
  location: '',
  description: '',
  activeType: 0,
  favorite: false,
  retailPrice: 0,
  consumerPrice: 0,
  speed: '',
  locationNetworkList: [],
  location_kr: '',
  name_kr: '',
  code: '',
};

// Atom to store the entire plans array
export const plansAtom = atom<Package[]>(initialPlansState);

// Writable atom for the picked plan
export const pickedPlanAtom = atom<Package>(initialPickedPlanState);
