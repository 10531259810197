// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
  Timestamp,
  where,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCz-jM5bTHY5IWjFSuJ93He-ZImJMvHMi0',
  authDomain: 'esim-connect.firebaseapp.com',
  projectId: 'esim-connect',
  storageBucket: 'esim-connect.appspot.com',
  messagingSenderId: '650941294441',
  appId: '1:650941294441:web:54e220881b14c00eb23ff8',
  measurementId: 'G-6THQHLLMZD',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const db = getFirestore(app);

export {
  addDoc,
  collection,
  db,
  doc,
  functions,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  startAfter,
  Timestamp,
  where,
};
