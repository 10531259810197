import { StarIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

interface CommentListingDataType {
  custName: string;
  date: string;
  comment: string;
  rating: number;
  plan: string;
}

export interface CommentListingProps {
  className?: string;
  data: CommentListingDataType;
  hasListingTitle?: boolean;
}

// const DEMO_DATA: CommentListingDataType = {
//   custName: 'J Kim',
//   date: 'August, 2024',
//   comment:
//     'There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant. ’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.',
//   rating: 5,
//   plan: 'Korea 10GB 30Days',
// };

const CommentListing: FC<CommentListingProps> = ({
  className = '',
  data,
  hasListingTitle,
}) => {
  return (
    <div
      className={`nc-CommentListing flex space-x-4 ${className}`}
      data-nc-id='CommentListing'
    >
      {/* <div className='pt-0.5'>
        <Avatar
          sizeClass='h-10 w-10 text-lg'
          radius='rounded-full'
          userName={data.name}
          imgUrl={data.avatar}
        />
      </div> */}
      <div className='flex-grow'>
        <div className='flex justify-between items-center space-x-3'>
          <div className='flex flex-col'>
            <div className='text-sm font-semibold'>
              <span className='text-base'>{data.custName}</span>

              <span className='text-neutral-500 dark:text-neutral-400 font-normal text-xs ml-2'>
                {data.date}
              </span>
            </div>
            <span className='text-sm text-neutral-500 dark:text-neutral-400 mt-0.5'>
              {data.plan}
            </span>
          </div>
          <div className='flex text-yellow-500'>
            {Array.from({ length: data.rating }).map((_, index) => (
              <StarIcon key={index} className='w-4 h-4' />
            ))}
          </div>
        </div>
        <span className='block mt-3 text-neutral-6000 dark:text-neutral-300'>
          <span className='text-sm'>{data.comment}</span>
        </span>
      </div>
    </div>
  );
};

export default CommentListing;
