import { TaxonomyType } from 'data/types';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';
import NcImage from 'shared/NcImage/NcImage';

export interface CardCategoryBox1Props {
  className?: string;
  taxonomy: TaxonomyType;
}
const CardCategoryBox2: FC<CardCategoryBox1Props> = ({
  className = '',
  taxonomy,
}) => {
  const { active, name, thumbnail, note, eng, href = '/' } = taxonomy;
  return (
    <Link
      to={href}
      className={`nc-CardCategoryBox1 relative flex items-center p-2 sm:p-4 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${
        active ? '' : `cursor-not-allowed`
      } ${className}`}
      data-nc-id='CardCategoryBox1'
    >
      {note && (
        <Badge
          className='absolute right-3 top-3 text-xs'
          color='blue'
          name={note}
        />
      )}

      {/* Set a fixed basis for imgflag and allow it to grow */}
      <div
        id='imgflag'
        className='relative flex-shrink-0 basis-1/3 sm:w-20 sm:h-20 w-10 h-10 rounded-full overflow-hidden'
      >
        <NcImage
          src={thumbnail}
          containerClassName='absolute inset-0'
          className='h-10 w-10'
        />
      </div>

      {/* Set a larger basis for countrytext and allow it to grow */}
      <div id='countrytext' className='ml-3 basis-2/3 overflow-hidden'>
        <h2 className='text-lg sm:text-base font-medium'>
          <span className='line-clamp-1'>{name}</span>
        </h2>
        <span
          className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
        >
          {eng}
        </span>
      </div>
    </Link>
  );
};

export default CardCategoryBox2;
