/**
 * HONEY ESIM 모바일 화면 하단 구매 표시
 */

import { useAtomValue } from 'jotai';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { currencyAtom } from 'store/atoms/currency';
import { Package } from 'type/interface';
import { useCurrencyConversion } from 'utils/currencyConversion';
import ModalReserveMobile from './ModalReserveMobile';

const NewMobileFooterSticky = ({ plan }: { plan: Package }) => {
  const { currencyConversion, exchangeRates } = useCurrencyConversion();

  const currencyState = useAtomValue(currencyAtom);

  return (
    <div className='block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40'>
      <div className='container flex items-center justify-between'>
        <div className='my-1 pl-4'>
          <div className='flex flex-col'>
            <div className='my-0 flex justify-between text-neutral-6000 dark:text-neutral-300 text-base'>
              <span>{plan.name_kr}</span>
            </div>

            <div className='my-0 flex justify-between text-neutral-6000 dark:text-neutral-300 text-sm'>
              <span className='font-light text-xs text-neutral-500 mr-1'>
                {plan.name}
              </span>
            </div>
          </div>
          <span className='block text-2xl font-semibold mt-0 ml-auto'>
            {(plan.consumerPrice / 10000).toLocaleString('en-US', {
              // style: 'currency',
              // currency: 'USD',
              minimumFractionDigits: 2,
              // maximumFractionDigits: 2,
            })}
            <span className='ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400'>
              {currencyState === 'KRW'
                ? `원 (${currencyState})`
                : currencyState}
            </span>
          </span>
        </div>
        <ModalReserveMobile
          renderChildren={({ openModal }) => (
            <ButtonPrimary
              className='ml-2 min-w-[90px]'
              sizeClass='px-3 sm:px-7 py-3 !rounded-xl'
              onClick={openModal}
            >
              <span className='font-semibold text-base'> 구매하기</span>
            </ButtonPrimary>
          )}
        />
      </div>
    </div>
  );
};

export default NewMobileFooterSticky;
