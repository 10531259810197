import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: any;
}

const IS_BEST_SELLER = (data: any) =>
  ['KR', 'JP', 'US', 'CN', 'AU', 'HK', 'TH', 'SG', 'TR'].includes(
    data.location,
  ) &&
  (data.slug.includes('10_30') || data.slug.includes('20_30'));
const IS_ON_SALE = IS_BEST_SELLER;

const NewFlightCard: FC<FlightCardProps> = ({ className = '', data }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/locations/${data.location.toLowerCase()}/${data.slug}`);
  };

  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 sm:hover:border sm:hover:border-primary-400 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden sm:hover:shadow-lg transition-shadow space-y-6 ${className} cursor-pointer`}
      data-nc-id='FlightCard'
      onClick={handleClick} // Add onClick handler
    >
      <div className={` relative ${className}`} data-nc-id='FlightCard'>
        <div className='flex flex-row items-center space-y-6 sm:space-y-0'>
          {/* 기간 */}
          <div id='period' className='lg:block flex-1'>
            <div className='text-xl flex items-center'>
              {/* Bold Capacity Display */}
              <span className='font-bold text-neutral-800 flex items-baseline'>
                {(data.volume / 1024 ** 3).toFixed(0)}
                <span className='text-lg text-neutral-600 ml-1'>GB</span>{' '}
                {/* GB label centered with value */}
              </span>

              {/* Subtle Duration Display */}
              <span className='text-base text-neutral-400 ml-2 flex items-baseline'>
                {' '}
                {/* Light grey color for subtle effect */}({data.duration}
                <span className=''>일</span>) {/* "일" centered with value */}
              </span>

              {/* Best Seller Badge */}
              {IS_BEST_SELLER(data) && (
                <Badge name='Best seller' className='ml-2' />
              )}
            </div>

            <div className='text-sm text-neutral-500 font-normal mt-0.5'>
              <span>{`${data.duration}일 동안 총 ${
                data.volume / 1024 ** 3
              }GB 데이터 제공`}</span>
            </div>
          </div>

          {/* PRICE (Moved to the right side on small screens) */}
          <div
            id='price-tag'
            className='pl-2 sm:text-right flex-none w-22 flex justify-end items-center'
          >
            <div className='text-xs font-light text-neutral-500 mt-0.3 mr-1'>
              {'USD'}
            </div>
            <div>
              {IS_ON_SALE(data) && (
                <span className='text-lg line-through font-light text-neutral-600'>
                  {((data.consumerPrice / 10000) * 1.3).toLocaleString(
                    'en-US',
                    {
                      // style: 'currency',
                      // currency: 'USD',
                      minimumFractionDigits: 0,
                      // maximumFractionDigits: 2,
                    },
                  )}
                  {/* <span>{currencyState === 'KRW' && '원'}</span> */}
                </span>
              )}

              <span className='text-xl  font-semibold text-neutral-600 ml-1'>
                {(data.consumerPrice / 10000).toLocaleString('en-US', {
                  // style: 'currency',
                  // currency: 'USD',
                  minimumFractionDigits: 0,
                  // maximumFractionDigits: 2,
                })}
                {/* <span>{currencyState === 'KRW' && '원'}</span> */}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFlightCard;
