export const COUNTRIES = [
  {
    name: 'AU',
    displayNameKr: '호주',
    displayNameEn: 'Australia',
    spelledOut: ['ㅎ', '호', '호주'],
  },
  {
    name: 'CN',
    displayNameKr: '중국',
    displayNameEn: 'China',
    spelledOut: ['ㅈ', '주', '중ㄱ', '중구', '중국'],
  },
  {
    name: 'HK',
    displayNameKr: '홍콩 (중국)',
    displayNameEn: 'Hong Kong',
    spelledOut: ['ㅎ', '홍', '홍ㅋ', '홍쿵', '홍콩'],
  },
  {
    name: 'ID',
    displayNameKr: '인도네시아',
    displayNameEn: 'Indonesia',
    spelledOut: ['ㅇ', '인', '인도', '인도네'],
  },
  {
    name: 'JP',
    displayNameKr: '일본',
    displayNameEn: 'Japan',
    spelledOut: ['ㅇ', '이', '일ㅂ', '일보'],
  },
  {
    name: 'MO',
    displayNameKr: '마카오 (중국)',
    displayNameEn: 'Macao',
    spelledOut: ['ㅁ', '마', '마카', '마카오'],
  },
  {
    name: 'MY',
    displayNameKr: '말레이시아',
    displayNameEn: 'Malaysia',
    spelledOut: ['ㅁ', '말', '말레', '말레이'],
  },
  {
    name: 'SG',
    displayNameKr: '싱가포르',
    displayNameEn: 'Singapore',
    spelledOut: ['ㅅ', '싱', '싱가'],
  },
  {
    name: 'KR',
    displayNameKr: '대한민국',
    displayNameEn: 'South Korea',
    spelledOut: ['ㅎ', '하', '한ㄱ', '한구'],
  },
  {
    name: 'TR',
    displayNameKr: '터키',
    displayNameEn: 'Turkey',
    spelledOut: ['ㅌ', '터', '터ㅋ', '터키'],
  },
  {
    name: 'TH',
    displayNameKr: '태국',
    displayNameEn: 'Thailand',
    spelledOut: ['ㅌ', '태', '태ㄱ', '태국'],
  },
  {
    name: 'US',
    displayNameKr: '미국',
    displayNameEn: 'USA',
    spelledOut: ['ㅁ', '믹', '미구'],
  },
  {
    name: 'BE',
    displayNameKr: '벨기에',
    displayNameEn: 'Belgium',
    spelledOut: ['ㅂ', '벨', '벨기'],
  },
  {
    name: 'HR',
    displayNameKr: '크로아티아',
    displayNameEn: 'Croatia',
    spelledOut: ['ㅋ', '크', '크로'],
  },
  {
    name: 'DK',
    displayNameKr: '덴마크',
    displayNameEn: 'Denmark',
    spelledOut: ['ㄷ', '덴', '덴마'],
  },
  {
    name: 'FR',
    displayNameKr: '프랑스',
    displayNameEn: 'France',
    spelledOut: ['ㅍ', '프', '프라', '프랑'],
  },
  {
    name: 'DE',
    displayNameKr: '독일',
    displayNameEn: 'Germany',
    spelledOut: ['ㄷ', '도', '독', '독일'],
  },
  {
    name: 'GR',
    displayNameKr: '그리스',
    displayNameEn: 'Greece',
    spelledOut: ['ㄱ', '그', '그리'],
  },
  {
    name: 'IE',
    displayNameKr: '아일랜드',
    displayNameEn: 'Ireland',
    spelledOut: ['ㅇ', '아', '아일'],
  },
  {
    name: 'IT',
    displayNameKr: '이탈리아',
    displayNameEn: 'Italy',
    spelledOut: ['ㅇ', '이', '이타'],
  },
  {
    name: 'NO',
    displayNameKr: '노르웨이',
    displayNameEn: 'Norway',
    spelledOut: ['ㄴ', '노', '노르'],
  },
  {
    name: 'PT',
    displayNameKr: '포르투갈',
    displayNameEn: 'Portugal',
    spelledOut: ['ㅍ', '포', '포르'],
  },
  {
    name: 'SE',
    displayNameKr: '스웨덴',
    displayNameEn: 'Sweden',
    spelledOut: ['ㅅ', '스', '스웨'],
  },
  {
    name: 'GB',
    displayNameKr: '영국',
    displayNameEn: 'United Kingdom',
    spelledOut: ['ㅇ', '영', '영구'],
  },
];

export const POPULAR_COUNTRIES = [
  { name: 'KR', displayNameKr: '대한민국', displayNameEn: 'Korea' },
  { name: 'JP', displayNameKr: '일본', displayNameEn: 'Japan' },
  { name: 'CN', displayNameKr: '중국', displayNameEn: 'China' },
  { name: 'US', displayNameKr: '미국', displayNameEn: 'USA' },
  { name: 'TH', displayNameKr: '태국', displayNameEn: 'Thailand' },
  { name: 'AU', displayNameKr: '호주', displayNameEn: 'Australia' },
  { name: 'FR', displayNameKr: '프랑스', displayNameEn: 'France' },
  { name: 'TR', displayNameKr: '터키', displayNameEn: 'Turkey' },
  { name: 'ID', displayNameKr: '인도네시아', displayNameEn: 'Indonesia' },
];
