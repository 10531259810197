/**
 *
 * NEW: 모든 국가들이 나열된 컴포넌트
 *
 */

import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';

const LocationsPage = () => {
  return (
    <>
      <section className='container relative z-20 overflow-hidden bg-white pt-5 sm:pb-12 sm:pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px] mb-2 h-max'>
        <SectionGridCategoryBox />
      </section>
    </>
  );
};

export default LocationsPage;
