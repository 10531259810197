import Heading from 'components/Heading/Heading';
import { FC } from 'react';

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
  plan: string;
}

const FOUNDER_DEMO: Statistic[] = [
  {
    id: '1',
    heading: '$36',
    subHeading: 'Ubigi',
    plan: '한국 30일',
  },
  {
    id: '2',
    heading: '$75',
    subHeading: 'Holafly',
    plan: '한국 30일',
  },
  {
    id: '3',
    heading: '$62',
    subHeading: 'Airalo',
    plan: '한국 30일',
  },
];

export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<SectionStatisticProps> = ({ className = '' }) => {
  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc='타 메이저 eSIM 판매사 대비 최고 60% 이상 저렴합니다'
        isCenter={true}
      >
        저렴한 가격
      </Heading>
      <div className='grid md:grid-cols-2 gap-3 lg:grid-cols-4 xl:gap-4'>
        {FOUNDER_DEMO.map((item) => (
          <div
            key={item.id}
            className='p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'
          >
            <span className='text-neutral-500'> {item.plan}</span>

            <h3 className='text-2xl  leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
              {item.heading}
            </h3>
            <span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400 font-bold'>
              {item.subHeading}
            </span>
          </div>
        ))}
        <div className='p-6 bg-primary-100 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 border border-primary-400'>
          <span className='text-neutral-500'> 한국 10GB 30일</span>

          <h3 className='text-3xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
            {'$12'}
          </h3>
          <span className='block text-sm text-neutral-700 mt-3 sm:text-base dark:text-neutral-400 font-bold'>
            {'꿀이심 👍'}
          </span>
        </div>
      </div>
      <div className='flex items-end mt-10'>
        <div className='ml-auto text-xs text-neutral-500'>
          * 2024년 11월 기준
        </div>
      </div>
    </div>
  );
};

export default SectionStatistic;
