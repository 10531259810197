import { collection, db, getDocs, query, where } from './firebase';

export const fetchDocsByCode = async ({ code }: { code: string }) => {
  const q = query(
    collection(db, 'PLANS_V2'),
    where('code', '==', code.toUpperCase()),
  );

  const querySnapshot = await getDocs(q);
  const docsArray = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  })) as any[];

  return docsArray;
};

export const fetchDocsBySlug = async ({ slug }: { slug: string }) => {
  const q = query(
    collection(db, 'PLANS_V2'),
    where('slug', '==', slug.toUpperCase()),
  );
  const querySnapshot = await getDocs(q);
  const docsArray = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
  })) as any[];

  return docsArray;
};
