import { collection, db, getDocs, limit, orderBy, query } from 'api/firebase';
import Heading from 'components/Heading/Heading';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Review {
  rating: number;
  comment: string;
  createdAt: Date;
  orderNo: string;
  custName: string;
  date: string;
  plan: string;
}

const ReviewCarousel = () => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [slidesPerView, setSlidesPerView] = useState(1);

  // Fetch the latest 30 reviews from Firestore
  const fetchReviews = async () => {
    try {
      const reviewsQuery = query(
        collection(db, 'REVIEWS_V2'),
        orderBy('createdAt', 'desc'),
        limit(30),
      );

      const querySnapshot = await getDocs(reviewsQuery);
      const fetchedReviews = querySnapshot.docs.map((doc) => ({
        ...(doc.data() as Review),
        createdAt: doc.data().createdAt.toDate(), // Ensure createdAt is a JavaScript Date object
      }));

      setReviews(fetchedReviews);
    } catch (error) {
      console.error('Error fetching reviews: ', error);
    }
  };

  // Shorten the comment text to fit within the card size
  const shortenComment = (comment: string, maxLength: number) =>
    comment.length > maxLength ? `${comment.slice(0, maxLength)}...` : comment;

  // Set slidesPerView based on screen size
  useEffect(() => {
    if (!reviews.length) {
      fetchReviews();
    }

    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSlidesPerView(3); // Show 3 reviews per slide on large screens
      } else if (window.innerWidth >= 768) {
        setSlidesPerView(2); // Show 2 reviews per slide on medium screens
      } else {
        setSlidesPerView(1); // Show 1 review per slide on small screens
      }
    };

    handleResize(); // Set initial slidesPerView based on screen size
    window.addEventListener('resize', handleResize); // Adjust on screen resize

    return () => window.removeEventListener('resize', handleResize);
  }, [reviews]);

  // Render the component
  return (
    <div className={`nc-SectionStatistic relative`}>
      <Heading
        desc='#너무나 저렴한 가격 #빠른 카톡 전송 #실시간 데이터 확인'
        isCenter={true}
        descClassName='font-semibold'
      >
        실제 고객 리뷰
      </Heading>
      <Swiper
        spaceBetween={30}
        slidesPerView={slidesPerView}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        // pagination={{ clickable: true }}
        modules={[Autoplay, Pagination]}
        className='reviewCarousel'
      >
        {reviews.map((review, i) => (
          <SwiperSlide key={i}>
            <Link
              to='/reviews'
              className='p-3 mb-10 bg-white rounded-lg shadow-lg h-36 flex flex-col justify-between'
            >
              {/* Comment Section with Fixed Height and Overflow */}
              <div className='text-lg font-semibold mb-2 h-20 overflow-hidden text-ellipsis'>
                {shortenComment(review.comment, 110)}
              </div>

              {/* Bottom Section for Rating, Customer Name, and Plan */}
              <div className='flex items-end justify-between mt-auto'>
                {/* Rating */}

                <div className='text-xs text-neutral-500 font-medium text-left'>
                  <div className='text-neutral-700'>{review.custName} </div>
                  <span className='font-light'>{review.plan}</span>
                </div>

                {/* Customer Name and Plan */}
                <div className='text-sm text-gray-600'>
                  {Array(review.rating).fill('⭐').join('')}
                </div>
              </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ReviewCarousel;
