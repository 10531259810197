import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionBecomeAnAuthor from 'components/SectionBecomeAnAuthor/SectionBecomeAnAuthor';
import SectionGridCategoryBox from 'components/SectionGridCategoryBox/SectionGridCategoryBox';
import SectionHero from 'components/SectionHero/SectionHero';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import PageAbout from 'containers/PageAbout/PageAbout';
import SectionStatistic from 'containers/PageAbout/SectionStatistic';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'shared/Alert/Alert';
import { currencyAtom } from 'store/atoms/currency';
import ReviewCarousel from './ReviewCarousel';

function PageHome() {
  const [, setCurrencyState] = useAtom(currencyAtom);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const currencyQuery = searchParams.get('currency');
    if (currencyQuery && (currencyQuery === 'usd' || currencyQuery === 'cad')) {
      setCurrencyState(currencyQuery.toUpperCase());
    }
  }, [searchParams, setCurrencyState]);

  return (
    <div className='nc-PageHome relative overflow-hidden'>
      <Alert type='warning' containerClassName='bg-primary-200 m-2'>
        <div>
          <div>🌼 새롭게 UI가 업데이트 되었습니다.</div>
          <div>🛜 실시간 데이터 사용량 확인, 📱 아이폰 퀵 설치 기능 추가</div>
        </div>
      </Alert>

      {/* 배경 번짐 */}
      <BgGlassmorphism />

      <div className='container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28'>
        {/* Hero - 메인 슬로건, 국가 검색 */}
        <SectionHero className='pt-10 lg:pt-16 lg:pb-16' />

        {/* 나라별 선택 */}
        <div className='relative py-12'>
          <BackgroundSection />
          <SectionGridCategoryBox />
        </div>

        <ReviewCarousel />

        {/* 꿀 이심 3사 가격비교 */}

        <SectionStatistic />

        {/* 사용방법 3가지 스텝 */}
        <div className='relative py-12'>
          <BackgroundSection className='bg-primary-50 dark:bg-black dark:bg-opacity-20 ' />
          <SectionHowItWork />
        </div>

        {/* 대표 후기 */}
        {/* <div className='flex justify-center'>
          <img src={Review} alt='Review Image' width='650px' />
        </div> */}

        {/* 꿀 이심 환불 */}
        <div className='relative py-12 sm:inline-block'>
          <SectionBecomeAnAuthor />
        </div>

        {/* 서비스 간략 소개 */}
        {/* <div className='hidden sm:inline-block'>
          <SectionOurFeatures />
        </div> */}
        {/* SECTION eSIM 가이드 */}
        <div className='relative py-16'>
          <BackgroundSection className='bg-primary-50 dark:bg-black dark:bg-opacity-20 ' />
          <PageAbout />
          {/* <SectionSubscribe2 /> */}
        </div>

        {/* <SectionSliderNewCategories uniqueClassName=""/> */}
      </div>
    </div>
  );
}

export default PageHome;
