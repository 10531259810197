import axios from 'axios';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { exchangeRatesAtom } from 'store/atoms/exchangeRates'; // Adjust the path as needed

const CURRENCY_API_URL = 'https://api.exchangerate-api.com/v4/latest/KRW';

export const useCurrencyConversion = () => {
  const [exchangeRates, setExchangeRates] = useAtom(exchangeRatesAtom);

  useEffect(() => {
    // Fetch exchange rates only if they don't exist (i.e., are null)
    if (!exchangeRates) {
      const fetchExchangeRates = async () => {
        try {
          const response = await axios.get(CURRENCY_API_URL);
          const rates = response.data.rates;
          setExchangeRates({
            KRW_TO_USD: rates.USD,
            KRW_TO_CAD: rates.CAD,
          });
        } catch (error) {
          // FALL BACK TO DEFAULT EXCHANGE RATES
          setExchangeRates({
            KRW_TO_USD: 0.000737,
            KRW_TO_CAD: 0.00102,
          });
          console.error('Error fetching exchange rates:', error);
        }
      };

      fetchExchangeRates();
    }
  }, [exchangeRates]);

  const currencyConversion = (krw: number, currency: string): string => {
    if (!exchangeRates) {
      // Return a default message or value while waiting for exchange rates to load
      return 'Loading...';
    }

    switch (currency) {
      case 'KRW':
        return krw?.toLocaleString('en-US');
      case 'USD':
        return (krw * exchangeRates.KRW_TO_USD).toFixed(2);
      case 'CAD':
        return (krw * exchangeRates.KRW_TO_CAD).toFixed(2);
      default:
        return krw?.toLocaleString('en-US');
    }
  };

  return { currencyConversion, exchangeRates };
};
