import { StarIcon } from '@heroicons/react/24/solid';
import { FC, useEffect, useState } from 'react';

export interface FiveStartIconForRateProps {
  className?: string;
  iconClass?: string;
  defaultPoint?: number;
  rating?: any;
  setRating?: any;
}

const FiveStartIconForRate: FC<FiveStartIconForRateProps> = ({
  className = '',
  iconClass = 'w-4 h-4',
  defaultPoint = 5,
  rating,
  setRating,
}) => {
  const [currentHover, setCurrentHover] = useState(0);

  useEffect(() => {
    setRating(defaultPoint);
  }, [defaultPoint]);

  return (
    <div
      className={`nc-FiveStartIconForRate flex items-center text-neutral-300 ${className}`}
      data-nc-id='FiveStartIconForRate'
    >
      {[1, 2, 3, 4, 5].map((item) => {
        return (
          <StarIcon
            key={item}
            className={`${
              rating >= item || currentHover >= item ? 'text-yellow-500' : ''
            } ${iconClass}`}
            onMouseEnter={() => setCurrentHover(() => item)}
            onMouseLeave={() => setCurrentHover(() => 0)}
            onClick={() => setRating(() => item)}
          />
        );
      })}
    </div>
  );
};

export default FiveStartIconForRate;
