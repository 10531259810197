/**
 * HONEY ESIM Product Page - South Korea
 */

import { fetchDocsByCode } from 'api/firestoreFunc';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import { plansAtom } from 'store/atoms/plans';
import NewSectionGridFilterCard from './NewSectionGridFilterCard';

export interface EsimPlanListingProps {
  className?: string;
}

const NewEsimPlanListing: FC<any> = ({ className = '' }) => {
  const { location } = useParams(); // KR
  // Reading the entire plan object
  const [plans, setPlans] = useAtom(plansAtom);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data: any = await fetchDocsByCode({ code: location as string });
        setPlans(data);
        setIsLoading(false);
      } catch (e) {
        console.log('Err@fetchDocsByCode', e);
      }
    };
    if (
      // 기존 나라로 검색한 플랜이 없거나 다른 나라를 선택할 경우, FETCH
      location &&
      (plans.length === 0 || plans[0].code !== location.toUpperCase())
    ) {
      fetchData();
    }
  }, [location]);

  return (
    <div
      className={`nc-EsimPlanListing relative ${className}`}
      data-nc-id='EsimPlanListing'
    >
      <Helmet>
        <title>꿀이심 || eSIM 플랜 리스트</title>
      </Helmet>
      <BgGlassmorphism />

      {/* 상품 리스팅 */}

      <div className='container relative'>
        {isLoading ? (
          <Loader />
        ) : (
          location && (
            <NewSectionGridFilterCard
              className='pb-24 lg:pb-28'
              plans={plans}
              location={location}
            />
          )
        )}
      </div>
    </div>
  );
};

export default NewEsimPlanListing;
