/**
 * HONEY ESIM 결제 완료 페이지
 */

import { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

import { useSearchParams } from 'react-router-dom';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = '' }) => {
  const [searchParams] = useSearchParams();

  const oid = searchParams.get('oid');
  const email = searchParams.get('email');
  const paid_amount = searchParams.get('paid_amount');

  const renderContent = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <h2 className='text-3xl lg:text-4xl font-semibold mt-8'>
          결제가 완료되었습니다 🎉
          <div className='text-base text-neutral-500 font-light mt-4'>
            곧 eSIM 정보가 이메일로 발송됩니다
            <div>
              이메일을 못 받으셨다면{' '}
              <a href='mailto:hello@honey-esim.com'>
                <span className='text-primary-500'> hello@honey-esim.com</span>
              </a>{' '}
              으로 문의주시면 감사하겠습니다
            </div>
          </div>
        </h2>
        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
        {/* ----------- 주문한 상품 내역 ------------- */}
        {/* <div className='space-y-6'>
          <h3 className='text-2xl font-semibold'>주문하신 상품</h3>
          <div className='flex flex-col sm:flex-row sm:items-center border rounded-2xl border-primary-400'>
            <div className='pt-5 pb-5 px-5 space-y-3'>
              <div>
                <span className='text-base text-neutral-500 dark:text-neutral-400 line-clamp-1'>
                  {plan_name_en}
                </span>
                <span className='text-base sm:text-lg font-medium mt-1 block'>
                  {plan_name_kr}
                </span>
              </div>
            </div>
          </div>
        </div> */}
        {/* --------- 주문 상세 정보 --------------- */}
        <div className='space-y-6 pt-4'>
          <h3 className='text-2xl font-semibold'>주문 상세 정보</h3>
          <div className='flex flex-col space-y-4'>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>오더 ID</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {oid}
              </span>
            </div>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>이메일</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {email}
              </span>
            </div>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>결제 금액</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {paid_amount}
              </span>
            </div>
          </div>
        </div>
        <div className='border-b border-neutral-200  dark:border-neutral-700'></div>
        <div className='flex justify-center pt-4'>
          <ButtonPrimary href='/locations'>최저가 eSIM 상품 보기</ButtonPrimary>
          <div className='mx-1'></div>
          <ButtonSecondary href='/'>홈으로 가기</ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id='PayPage'>
      <main className='container mt-11 mb-24 lg:mb-32 '>
        <div className='max-w-4xl mx-auto'>{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
