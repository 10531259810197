/**
 * HONEY ESIM Product Page - South Korea
 */
import NewFlightCard from 'components/FlightCard/NewFlightCard';
import Heading2 from 'components/Heading/Heading2';
import { FC, useEffect, useState } from 'react';
import { Package } from 'type/interface';

export interface NewSectionGridFilterCardProps {
  className?: string;
  plans: Package[];
  location: string;
}

interface TabType {
  id: string;
  name: string;
}

const PLAN_CATEGORY_TABS: TabType[] = [
  { id: 'single-data', name: '데이터 종량제' },
  // { id: 'multi-data', name: '멀티국가' },
  // { id: 'single-daily', name: '데일리 플랜' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const NewSectionGridFilterCard: FC<NewSectionGridFilterCardProps> = ({
  className = '',
  plans,
}) => {
  const [selectedPlanCategoryTabId, setSelectedPlanCategoryTabId] =
    useState('single-data');
  const [panelHeight, setPanelHeight] = useState<number>(0);

  // Calculate panel height based on viewport height minus header and footer
  const calculatePanelHeight = () => {
    const headerHeight = document.querySelector('header')?.clientHeight || 0;
    const footerHeight = document.querySelector('footer')?.clientHeight || 0;
    const availableHeight =
      window.innerHeight - headerHeight - footerHeight - 320;
    setPanelHeight(availableHeight);
  };

  useEffect(() => {
    calculatePanelHeight();
    window.addEventListener('resize', calculatePanelHeight);
    return () => {
      window.removeEventListener('resize', calculatePanelHeight);
    };
  }, []);

  // Sort plans by duration and then by volume
  const sortedPlans = [...plans]
    .filter((plan) =>
      selectedPlanCategoryTabId === 'single-data'
        ? plan.location.length === 2
        : plan.location.includes(','),
    )
    .sort((a, b) => a.duration - b.duration || a.volume - b.volume);

  return plans.length > 0 ? (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id='SectionGridFilterCard'
    >
      <Heading2
        heading={plans[0].location_kr}
        subHeading={
          <span className='text-neutral-400 text-sm'>
            총{' '}
            <span className='text-neutral-500 text-base'>{plans.length}</span>{' '}
            개의 상품
          </span>
        }
      />

      {/* Category Tabs */}
      <nav className='border-b border-gray-200'>
        <div className='flex -mb-px'>
          {PLAN_CATEGORY_TABS.map((tab) => (
            <div
              key={tab.id}
              className={classNames(
                tab.id === selectedPlanCategoryTabId
                  ? 'border-primary-500 text-primary-600'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'w-1/2 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer',
              )}
              onClick={() => setSelectedPlanCategoryTabId(tab.id)}
            >
              <span className='text-xl font-semibold'>{tab.name}</span>
            </div>
          ))}
        </div>
      </nav>

      {/* Plans List */}
      <div
        style={{ maxHeight: `${panelHeight}px`, overflowY: 'auto' }}
        className='mt-2'
      >
        {sortedPlans.length === 0 ? (
          <div className='text-neutral-600 ml-4'>
            상품이 존재하지 않습니다 😔
          </div>
        ) : (
          <div className='lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 rounded-3xl overflow-hidden py-2'>
            {sortedPlans.map((plan, index) => (
              <NewFlightCard key={`card-${index}`} data={plan} />
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default NewSectionGridFilterCard;
