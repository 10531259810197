/**
 * HONEY ESIM 메인 페이지 HERO SECTION
 */

import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm';
import { FC } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse  relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='flex flex-col items-center'>
        <div className='flex-shrink-0  flex flex-col items-center  space-y-8 sm:space-y-10 pb-14'>
          <h2 className='font-medium text-4xl md:text-4xl xl:text-5xl !leading-[110%] text-center'>
            유심은 버리고
            <div className='font-semibold'>최저가 데이터로 </div>
            <div className='font-semibold'>가벼운 여행을!</div>
            {/* <div className='font-semibold md:text-6xl xl:text-7xl mt-2'>
              꿀eSIM
            </div> */}
          </h2>

          <span className='text-lg md:text-xl text-neutral-500 dark:text-neutral-400 flex flex-col items-center'>
            <div className=''>🇰🇷 전세계 최저가 한국 eSIM</div>
            <div className='mt-1'>🗨️ 결제 즉시 카톡·이메일 발송</div>
            <div className='mt-1'>🛜 실시간 데이터 사용량 확인</div>
            <div className='mt-1'>🗓️ 6개월의 넉넉한 유효기간</div>
            <div className='mt-1'>📱 아이폰 퀵 설치 기능까지!</div>
            <div className='mt-1'> 🇺🇸🇨🇦 해외 교민분들을 위한 eSIM 서비스</div>
          </span>
          <ButtonPrimary className={'bg-neutral-100'} href='/locations'>
            <span className='text-lg'>최저가 eSIM 상품 보기</span>
          </ButtonPrimary>
        </div>
        {/* <div className='flex-grow hidden lg:block'>
          <img className='w-full' src={imagePng} alt='hero' />
        </div> */}
      </div>

      {/* 국가검색 */}
      <div className='hidden md:flex justify-center z-10 mb-10 w-1/2 mx-auto'>
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero;
