/**
 * HONEY ESIM 최종 결제 페이지 https://developer.payple.kr/global/payment-window#c48d54f2-ec93-4d55-a72c-e5da2dba6ab5   https://developer.payple.kr/224bffbb-ba39-41bd-9cf2-0c9fe3ed62bf
 */

import axios from 'axios';
import Label from 'components/Label/Label';
import JCB from 'images/jcb.png';
import MASTER from 'images/master-logo.png';
import VISA from 'images/visa.png';
import { Helmet } from 'react-helmet';

import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';

import Input from 'shared/Input/Input';

import { fetchDocsByCode } from 'api/firestoreFunc';
import { useLocation } from 'react-router-dom';
import { currencyAtom } from 'store/atoms/currency';
import { pickedPlanAtom, plansAtom } from 'store/atoms/plans';

function generateCustomId(): string {
  // Get the current date
  const now = new Date();

  // Format date components
  const year = String(now.getFullYear()).slice(-2); // last two digits of the year
  const month = String(now.getMonth() + 1).padStart(2, '0'); // month (01-12)
  const day = String(now.getDate()).padStart(2, '0'); // day (01-31)

  // Generate a random six-character string with mixed case
  const randomString = Array.from({ length: 5 }, () => {
    const isUpperCase = Math.random() > 0.5;
    const charCode = isUpperCase
      ? 65 + Math.floor(Math.random() * 26) // uppercase A-Z
      : 97 + Math.floor(Math.random() * 26); // lowercase a-z
    return String.fromCharCode(charCode);
  }).join('');

  // Combine everything in the desired format
  // V => HONEY ESIM V2 VERSION CODE
  return `V${year}${month}${day}${randomString}`;
}

const requestToken = async (): Promise<string[]> => {
  try {
    const { data } = await axios.post(
      'https://us-central1-esim-connect.cloudfunctions.net/requestAuthTokenStatic',
    );
    return [data.access_token, data.payCls];
  } catch (error) {
    return ['error'];
  }
};
export interface CheckOutPagePageMainProps {
  className?: string;
}

const CheckOutPagePageMain: FC<CheckOutPagePageMainProps> = ({
  className = '',
}) => {
  const [currencyState, setCurrencyState] = useAtom(currencyAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const url_location = useLocation();

  const { location, slug } = useParams();

  const [pickedPlanState, setPickedPlanState] = useAtom(pickedPlanAtom);
  const [plansState, setPlansState] = useAtom(plansAtom);

  useEffect(() => {
    if (location && slug && !plansState.length) {
      setIsLoading(true);
      try {
        const data: any = fetchDocsByCode({ code: location as string });
        setPlansState(data);
      } catch (e) {
        console.log('Err@fetchDocsByCode', e);
      }
      setIsLoading(false);
    } else {
      const picked: any = plansState.find((d) => d.slug === slug);
      setPickedPlanState(picked);
    }
  }, [slug, plansState]); // Added dependencies

  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm();

  // PAY HANDLER 결제창 띄우기
  const onSubmit = async (data: any, event: any) => {
    const current_domain = window.location.origin;
    const current_url = `${current_domain}${url_location.pathname}${url_location.search}`;

    try {
      setIsLoading(true);
      setCurrencyState('USD'); // USD만 결제 가능

      let { email, phone } = data;
      email = data.email.trim().toLowerCase();
      phone = data.phone.trim();

      // 1. Get Auth token from payple
      const [accessToken, payCls] = await requestToken();

      // 2. This could contain multiple plans. Create order object (TODO: later this should be set as store)
      const order = [
        {
          email,
          phone,
          plan: pickedPlanState,
          count: 1,
        },
      ];

      /* pickedPlanState
         {
            "packageCode": "JC075",
            "slug": "KR_10_30",
            "name": "South Korea 10GB 30Days",
            "currencyCode": "USD",
            "volume": 10737418240,
            "smsStatus": 0,
            "dataType": 1,
            "unusedValidTime": 180,
            "duration": 30,
            "durationUnit": "DAY",
            "location": "KR",
            "description": "South Korea 10GB 30Days",
            "activeType": 2,
            "favorite": true,
            "retailPrice": 100000,
            "speed": "3G/4G/5G",
            "locationNetworkList": [
                {
                    "locationName": "South Korea",
                    "locationLogo": "/img/flags/kr.png",
                    "operatorList": [
                        {
                            "operatorName": "SKTelecom",
                            "networkType": "4G"
                        }
                    ]
                }
            ],
            "consumerPrice": 110000,
            "location_kr": "한국",
            "name_kr": "한국 10GB 30일"
        },
         */

      // 3. Get Total Amount and # of eSIMs
      const totalAmount =
        order.reduce((accumulator, item) => {
          return accumulator + item.plan.consumerPrice * item.count;
        }, 0) / 10000;

      const totalEsimNum = order.reduce((accumulator, item) => {
        return accumulator + item.count;
      }, 0);

      // 4. Encode custom slug
      const serviceDefine = {
        order,
        current_url,
        current_domain,
        app: 'HONEY_ESIM_V2',
        totalAmount,
        totalEsimNum,
      };

      const encodedServiceDefine = btoa(
        encodeURIComponent(JSON.stringify(serviceDefine)),
      );

      // 5. Construct pplPaymentObj
      const pplPaymentObj: any = {
        service_id: 'esim', // [필수] 파트너 ID
        service_oid: generateCustomId(), // [선택] 주문번호(미지정하는 경우 페이플에서 임의로 지정)
        serviceDefine: encodedServiceDefine, //  BASE64로 활용하기!! [선택] 사용자 정의 파라미터입니다. (길이: 2408)
        comments:
          totalEsimNum === 1
            ? order[0].plan.name_kr
            : `${order[0].plan.name_kr} 포함 총 ${totalEsimNum}개`, // [필수] 상품명
        totalAmount, // 7 (70000 아님)
        currency: 'USD', // [필수] 통화
        email, // [선택] 이메일 주소
        phoneNumber: phone,
        firstName: '', // [선택] 카드소유주 이름
        lastName: '', // [선택] 카드소유주 성
        resultUrl:
          'https://us-central1-esim-connect.cloudfunctions.net/txResultWebhookV2', // [필수] 결제결과 반환(Return) URL
        isDirect: 'Y', // [선택] 결제창 호출 다이렉트 여부 ("" | "Y")
        payCls: payCls,
        Authorization: accessToken,
      };

      window.paypleGpayPaymentRequest(pplPaymentObj);
    } catch (e) {
      console.log('ERR@OnSubmit', e);
    }
    setIsLoading(false);
  };

  const repeatedEmail = watch('email');

  // Function to format the phone number as user types
  const handlePhoneNumberChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedPhoneNumber = input.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '$1-$2-$3',
    );
    setValue('phone', formattedPhoneNumber);
  };

  const renderSidebar = () => {
    return (
      <div className='w-full flex flex-col rounded-2xl border dark:border-neutral-700 px-0 sm:p-2 xl:p-4 border-primary-300'>
        <div className='flex flex-col sm:flex-row sm:items-center'>
          <div className='py-3 px-5 space-y-2'>
            <div>
              <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'></span>
              <span className='text-2xl font-medium block'>
                {`${pickedPlanState?.name_kr}`}{' '}
              </span>
            </div>
            <span className='block text-base text-neutral-500 dark:text-neutral-400'>
              데이터 종량제{' '}
            </span>
          </div>
        </div>
        <div className='flex flex-col space-y-3 pr-4 '>
          {/* <div className='flex items-center ml-auto'>
            {' '}
            <Input
              placeholder='할인코드'
              type='text'
              className={`ml-2 mr-4 max-w-72 ${
                discountPercent ? 'bg-gray-300' : ''
              }`}
              value={discountCode || selectedPlanState.discount_code || ''}
              onChange={handleInputChange}
              disabled={!!discountPercent}
            />
            <ButtonSecondary
              onClick={validateDiscountCode}
              disabled={!!discountPercent}
              className='w-36'
              type='button'
            >
              적용하기
            </ButtonSecondary>
          </div> */}
          <div className='text-xs ml-auto mr-2 text-slate-700'> {message} </div>
          <div className='flex justify-between text-neutral-500 dark:text-neutral-200 text-light text-sm'></div>

          <div className='border-b border-neutral-200 dark:border-neutral-700 ml-2 '></div>
          <span className='text-xs text-red-400 ml-auto'>
            *신용카드는 USD 통화만 결제 가능합니다
          </span>
          <div className='flex justify-between font-semibold pb-4 items-center'>
            <div className='flex justify-start items-center'>
              <NcImage src={VISA} className='w-12 mr-2 ml-2' />
              <NcImage src={MASTER} className='w-10 mr-2' />
              <NcImage src={JCB} className='w-8' />
            </div>
            <span className='text-neutral-400 font-light text-sm ml-auto mr-1'>
              {currencyState}
            </span>
            <span className='font-semibold text-2xl'>
              {(pickedPlanState?.consumerPrice / 10000).toLocaleString(
                'en-US',
                {
                  minimumFractionDigits: 2,
                },
              )}
              <span className='text-neutral-400 font-light text-sm'>
                {' '}
                {currencyState === 'KRW' ? '원' : ''}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {' '}
      <Helmet>
        <meta charSet='UTF-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <div className='nc-CheckOutPagePageMain'>
        <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'>
          <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10'>
            <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
              {/* <h2 className='text-3xl lg:text-4xl font-semibold'>
              주문 상세 내역
            </h2> */}

              {/* 구매시 유의사항 */}

              {/* 구매자 정보 */}
              <div>
                <h3
                  className='text-2xl font-semibold
              '
                >
                  주문자 정보
                </h3>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 my-5 hidden lg:block'></div>

                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  {/* 이메일 번호 입력 */}
                  <div className=''>
                    <div className='space-y-4'>
                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>이메일</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          QR코드 받으실 이메일 주소
                        </span>
                        <Input
                          {...register('email', {
                            onBlur: () => trigger('email'),
                            validate: (value) => {
                              const emailRegex =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                              if (!value) {
                                return '이메일을 꼭 적어주세요';
                              }
                              if (!emailRegex.test(value)) {
                                return '이메일 형식이 올바르지 않아요';
                              }
                              return true;
                            },
                          })}
                          placeholder='example@email.com'
                          type='email'
                        />
                        {errors.email && (
                          <p className='text-red-500 text-xs ml-1'>
                            {(errors.email.message as any) || ' '}
                          </p>
                        )}
                      </div>

                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>이메일(반복)</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          오타방지를 위해 한번 더 입력해주세요
                        </span>

                        <Input
                          {...register('email_repeat', {
                            onBlur: () => trigger('email_repeat'),
                            validate: (value) => {
                              if (!value) {
                                return '이메일을 꼭 적어주세요';
                              }
                              if (value !== repeatedEmail) {
                                return '이메일이 서로 일치하지 않아요';
                              }
                              return true;
                            },
                          })}
                          placeholder='example@email.com'
                          type='email'
                        />
                        {errors.email_repeat && (
                          <p className='text-red-500 text-xs ml-1'>
                            {errors.email_repeat.message as any}
                          </p>
                        )}
                      </div>
                      {/* 핸드폰 번호 체크 */}
                      <div className='space-y-1'>
                        <Label>
                          <span className='text-lg'>핸드폰 번호</span>
                        </Label>
                        <span className='ml-2 text-neutral-500 text-xs'>
                          핸드폰 번호 (캐나다, 미국)
                        </span>
                        <Input
                          {...register('phone', {
                            onBlur: () => trigger('phone'),
                            onChange: (e) => {
                              handlePhoneNumberChange(e);
                            },
                            validate: (value) => {
                              const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
                              if (!value) {
                                return '핸드폰 번호 10자리를 넣어주세요';
                              }
                              if (!phoneRegex.test(value)) {
                                return '유효하지 않은 번호 형식입니다';
                              }
                              return true;
                            },
                          })}
                          type='tel'
                          placeholder='123-456-7890'
                          maxLength={12} // Adjust as per your requirement
                        />

                        {errors.phone && (
                          <p className='text-red-500 text-xs ml-1'>
                            {errors.phone.message as any}
                          </p>
                        )}
                      </div>
                      {/* <div className='space-y-1'>
                        <div className={`flex text-sm sm:text-base`}>
                          <input
                            {...register('agree', {
                              onBlur: () => trigger('agree'),

                              required: '필수 동의사항입니다.', // Use 'required' for checkbox validation
                            })}
                            type='checkbox'
                            className='focus:ring-action-primary h-6 w-6 text-primary-400 border-primary rounded border-neutral-400 bg-white dark:bg-neutral-700  checked:bg-primary-400 focus:ring-primary-400'
                          />

                          <label
                            htmlFor='agree'
                            className='ml-3.5 flex flex-col flex-1 justify-center'
                          >
                            <span className=' text-neutral-900 dark:text-neutral-100'>
                              환불 규정 및 eSIM 사용 가능 기기 임을 확인했습니다{' '}
                            </span>
                          </label>
                        </div>
                        {errors.agree && (
                          <p className='text-red-500 text-xs ml-1'>
                            {errors.agree.message as any}
                          </p>
                        )}
                      </div> */}
                    </div>
                  </div>

                  <div className='mt-10'></div>

                  {/* 상품 설명 */}

                  <div className='block flex-grow '>{renderSidebar()}</div>

                  <div
                    id='submit_button'
                    className='flex flex-col sm:flex-row mt-2 items-center sm:justify-end'
                  >
                    <div className='w-full lg:w-auto mx-2'>
                      <ButtonPrimary
                        disabled={isLoading} //TODO: change it to isLoading
                        loading={isLoading}
                        type='submit'
                        className={'font-semibold xs:text-xl w-full lg:w-auto'}
                      >
                        <div className='font-semibold mr-2 text-lg'>
                          USD{' '}
                          {(
                            pickedPlanState.consumerPrice / 10000
                          ).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                        <div className='font-light text-lg'>결제하기</div>
                      </ButtonPrimary>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default CheckOutPagePageMain;
