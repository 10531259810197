/**
 * HONEY ESIM 나라별 선택
 */

import CardCategoryBox2 from 'components/CardCategoryBox1/CardCategoryBox2';
import { TaxonomyType } from 'data/types';
import React from 'react';

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: 'card1';
  className?: string;
  gridClassName?: string;
}

const countries: TaxonomyType[] = [
  {
    id: '1',
    href: '/locations/KR',
    name: '대한민국',
    taxonomy: 'category',
    count: undefined,
    eng: 'Korea',
    note: '인기',
    active: true,
    thumbnail: '/img/flags/kr.png',
  },
  {
    id: '2',
    href: '/locations/JP',
    name: '일본',
    taxonomy: 'category',
    count: undefined,
    eng: 'Japan',
    note: '인기',
    active: true,
    thumbnail: '/img/flags/jp.png',
  },
  {
    id: '3',
    href: '/locations/CN',
    name: '중국',
    taxonomy: 'category',
    count: undefined,
    eng: 'China',
    note: '인기',
    active: true,
    thumbnail: '/img/flags/cn.png',
  },
  {
    id: '4',
    href: '/locations/HK',
    name: '홍콩',
    taxonomy: 'category',
    count: undefined,
    eng: 'Hong Kong',
    note: '인기',
    active: true,
    thumbnail: '/img/flags/hk.png',
  },
  {
    id: '5',
    href: '/locations/MO',
    name: '마카오',
    taxonomy: 'category',
    count: undefined,
    eng: 'Macau',
    active: true,
    thumbnail: '/img/flags/mo.png',
  },
  {
    id: '6',
    href: '/locations/SG',
    name: '싱가포르',
    taxonomy: 'category',
    count: undefined,
    eng: 'Singapore',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/sg.png',
  },
  {
    id: '7',
    href: '/locations/ID',
    name: '인도네시아',
    taxonomy: 'category',
    count: undefined,
    eng: 'Indonesia',
    active: true,
    note: '',
    thumbnail: '/img/flags/id.png',
  },
  {
    id: '8',
    href: '/locations/MY',
    name: '말레이시아',
    taxonomy: 'category',
    count: undefined,
    eng: 'Malaysia',
    active: true,
    note: '',
    thumbnail: '/img/flags/my.png',
  },
  {
    id: '9',
    href: '/locations/TH',
    name: '태국',
    taxonomy: 'category',
    count: undefined,
    eng: 'Thailand',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/th.png',
  },
  {
    id: '10',
    href: '/locations/TR',
    name: '터키',
    taxonomy: 'category',
    count: undefined,
    eng: 'Turkey',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/tr.png',
  },
  {
    id: '11',
    href: '/locations/US',
    name: '미국',
    taxonomy: 'category',
    count: undefined,
    eng: 'USA',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/us.png',
  },
  {
    id: '12',
    href: '/locations/BE',
    name: '벨기에',
    taxonomy: 'category',
    count: undefined,
    eng: 'Belgium',
    active: true,
    thumbnail: '/img/flags/be.png',
  },
  {
    id: '13',
    href: '/locations/HR',
    name: '크로아티아',
    taxonomy: 'category',
    count: undefined,
    eng: 'Croatia',
    active: true,
    thumbnail: '/img/flags/hr.png',
  },
  {
    id: '14',
    href: '/locations/DK',
    name: '덴마크',
    taxonomy: 'category',
    count: undefined,
    eng: 'Denmark',
    active: true,
    thumbnail: '/img/flags/dk.png',
  },
  {
    id: '15',
    href: '/locations/FR',
    name: '프랑스',
    taxonomy: 'category',
    count: undefined,
    eng: 'France',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/fr.png',
  },
  {
    id: '16',
    href: '/locations/DE',
    name: '독일',
    taxonomy: 'category',
    count: undefined,
    eng: 'Germany',
    active: true,
    thumbnail: '/img/flags/de.png',
  },
  {
    id: '17',
    href: '/locations/GR',
    name: '그리스',
    taxonomy: 'category',
    count: undefined,
    eng: 'Greece',
    active: true,
    thumbnail: '/img/flags/gr.png',
  },
  {
    id: '18',
    href: '/locations/IE',
    name: '아일랜드',
    taxonomy: 'category',
    count: undefined,
    eng: 'Ireland',
    active: true,
    thumbnail: '/img/flags/ie.png',
  },
  {
    id: '19',
    href: '/locations/IT',
    name: '이탈리아',
    taxonomy: 'category',
    count: undefined,
    eng: 'Italy',
    active: true,
    thumbnail: '/img/flags/it.png',
  },
  {
    id: '20',
    href: '/locations/NO',
    name: '노르웨이',
    taxonomy: 'category',
    count: undefined,
    eng: 'Norway',
    active: true,
    thumbnail: '/img/flags/no.png',
  },
  {
    id: '21',
    href: '/locations/PT',
    name: '포르투갈',
    taxonomy: 'category',
    count: undefined,
    eng: 'Portugal',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/pt.png',
  },
  {
    id: '22',
    href: '/locations/SE',
    name: '스웨덴',
    taxonomy: 'category',
    count: undefined,
    eng: 'Sweden',
    active: true,
    thumbnail: '/img/flags/se.png',
  },
  {
    id: '23',
    href: '/locations/GB',
    name: '영국',
    taxonomy: 'category',
    count: undefined,
    eng: 'United Kingdom',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/gb.png',
  },
  {
    id: '24',
    href: '/locations/AU',
    name: '호주',
    taxonomy: 'category',
    count: undefined,
    eng: 'Australia',
    active: true,
    note: '인기',
    thumbnail: '/img/flags/au.png',
  },
];

const ASIA = [
  'Korea',
  'Japan',
  'China',
  'Hong Kong',
  'Macau',
  'Singapore',
  'Indonesia',
  'Malaysia',
  'Thailand',
];
const EUROPE = [
  'Belgium',
  'Croatia',
  'Denmark',
  'France',
  'Germany',
  'Greece',
  'Ireland',
  'Italy',
  'Norway',
  'Portugal',
  'Sweden',
  'United Kingdom',
];
const NORTH_AMERICA = ['USA'];

const AUSTRALIA = ['Australia'];

const sortedCountries = countries.sort((a, b) => {
  if (a.note === '인기' && b.note !== '인기') return -1;
  if (a.note !== '인기' && b.note === '인기') return 1;

  // Determine continent based on `eng` field
  const getContinent = (country: any) => {
    if (ASIA.includes(country.eng)) return 'A';
    if (NORTH_AMERICA.includes(country.eng)) return 'B';
    if (EUROPE.includes(country.eng)) return 'C';
    if (AUSTRALIA.includes(country.eng)) return 'D';

    return 'Other';
  };

  // Sort by continent if notes are the same
  return getContinent(a).localeCompare(getContinent(b));
});

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = sortedCountries,
  categoryCardType = 'card1',
  headingCenter = true,
  className = '',
  gridClassName = 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6',
}) => {
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      {/* <Heading desc='여행하시는 나라를 선택해주세요' isCenter={headingCenter}>
        eSIM 상품
      </Heading> */}
      <div className={`grid ${gridClassName} gap-2 sm:gap-6 md:gap-4`}>
        {categories.map((item, i) => (
          <CardCategoryBox2 key={i} taxonomy={item} />
        ))}
      </div>
      <div className='text-sm mt-2 font-light text-neutral-400'>
        원하시는 국가가 없으신가요?
        <div>
          <a
            href='mailto:hello@honey-esim.com'
            className='text-blue-500 underline'
          >
            hello@honey-esim.com
          </a>
          으로 문의 주세요
        </div>
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
