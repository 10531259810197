/**
 * HONEY ESIM 메인 페이지- ESIM 상품 카드
 */

import Heading from 'components/Heading/Heading';
import VectorImg from 'images/VectorHIW.svg';
import HIW1img from 'images/how_it_works/how_it_works1.png';
import HIW2img from 'images/how_it_works/how_it_works2.png';
import HIW3img from 'images/how_it_works/how_it_works3.png';
import { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps['data'] = [
  {
    id: 1,
    img: HIW1img,
    title: '내게 맞는 플랜 선택',
    desc: '사용일과 데이터 용량 선택',
  },
  {
    id: 2,
    img: HIW2img,
    title: '빠른 카톡·이메일 배송',
    desc: 'QR코드 스캔 및 eSIM 설치',
  },
  {
    id: 3,
    img: HIW3img,
    title: '현지 네트워크 접속',
    desc: '바로 현지에서 데이터 사용가능!',
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = '',
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id='SectionHowItWork'
    >
      <Heading isCenter desc=''>
        꿀eSIM 사용방법
      </Heading>
      <div className='mt-4 sm:mt-20 relative grid md:grid-cols-3 gap-20'>
        <img
          className='hidden md:block absolute inset-x-0 top-10'
          src={VectorImg}
          alt=''
        />
        {data.map((item) => (
          <div
            key={item.id}
            className='relative flex flex-col items-center max-w-xs mx-auto'
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName='dark:hidden block mb-8 max-w-[200px] mx-auto'
                  src={item.img}
                />
                <NcImage
                  containerClassName='hidden dark:block mb-8 max-w-[200px] mx-auto'
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName='sm:mb-8 w-20 sm:w-[200px] mx-auto'
                src={item.img}
              />
            )}
            <div className='text-center mt-auto'>
              <h1 className='md:text-3xl font-semibold mb-5'>{item.title}</h1>
              <span className='md:text-xl block mt-5 text-neutral-600 dark:text-neutral-400'>
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
