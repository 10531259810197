/**
 * HONEY ESIM Product Detail Page - ESIM 상품 카드
 */

import { Dialog, Transition } from '@headlessui/react';
import SupportedDevices from 'containers/PageAddListing1/SupportedDevices';
import { useAtom, useAtomValue } from 'jotai';
import { FC, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Loader from 'shared/Loader/Loader';
import { currencyAtom } from 'store/atoms/currency';
import { pickedPlanAtom, plansAtom } from 'store/atoms/plans';

import { useCurrencyConversion } from 'utils/currencyConversion';
import NewMobileFooterSticky from '../(components)/NewMobileFooterSticky';
import DetailPagetLayout from '../Layout';

import { fetchDocsByCode } from 'api/firestoreFunc';

const generatPlanDesc = (data: any) => {
  const isDailyPlan = data.categoryId === 'dp';
  const isUnlimited = !!data.unlimiitedCapacity;
};

const StayDetailPageContainer: FC<{}> = () => {
  const { currencyConversion, exchangeRates } = useCurrencyConversion();
  const { location, slug } = useParams();
  const navigate = useNavigate();

  const currencyState = useAtomValue(currencyAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [pickedPlanState, setPickedPlanState] = useAtom(pickedPlanAtom);
  const [plansState, setPlansState] = useAtom(plansAtom);

  useEffect(() => {
    if (location && slug && !plansState.length) {
      setIsLoading(true);
      try {
        const data: any = fetchDocsByCode({ code: location as string });
        setPlansState(data);
      } catch (e) {
        console.log('Err@fetchDocsByCode', e);
      }
      setIsLoading(false);
    } else {
      const picked: any = plansState.find((d) => d.slug === slug);
      setPickedPlanState(picked);
    }
  }, [slug, plansState]); // Added dependencies

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const notes = [
    {
      name:
        pickedPlanState.activeType === 2
          ? '출국 전 eSIM 설치가 가능하며, 현지 도착 후 사용일이 계산됩니다'
          : '설치 후 바로 사용일 수가 차감됩니다',
    },
    { name: '와이파이가 강한 곳에서 eSIM을 설치해주세요' },

    {
      name:
        pickedPlanState.dataType === 1
          ? '데이터가 한정된 종량제 상품입니다'
          : '데이타가 매일 갱신되는 데일리 플랜 상품입니다',
    },
    {
      name: `eSIM 유효기간은 ${pickedPlanState.unusedValidTime}일 이며, 기간 내에 현지 네트워크에 접속해주세요`,
    },
    // {
    //   name: 'eSIM 유효기간과 사용일수는 별개이며, 남은 유효기간에 상관없이 사용일수만큼 사용하실 수 있습니다',
    // },
    { name: 'eSIM 설치 후 기기변경이 불가합니다' },
  ];

  const renderEsimSupportedDevice = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModalAmenities}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full max-w-4xl'>
                <div className='inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      eSIM 지원 기기
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className='px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <SupportedDevices />

                    {/* {supported_devices.map((item, i) => (
                      <div
                        key={i}
                        className='flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8'
                      >
                        <span
                          className={`text-xs sm:text-md text-neutral-2000`}
                        >
                          {item.brand}
                        </span>
                        <span className={`text-xs sm:text-sm`}>
                          {item.device}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className='my-2 px-8 text-neutral-700 dark:text-neutral-300 text-center'></div> */}

                    {/* <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 중국에서 출시된 아이폰은 지원되지 않습니다
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 홍콩,마카오에서 출시된 아이폰은 지원되지 않습니다 (예외 -
                    iPhone 13 mini, iPhone 12 mini, iPhone SE 2020, iPhone XS)
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 한국에서 출시된 다음 갤럭시 모델은 지원되지 않습니다
                    (Galaxy S20, Galaxy S20+ 5G, Galaxy S20 Ultra, Galaxy S20
                    Ultra 5G, Galaxy S21, Galaxy S21+ 5G, Galaxy S21 Ultra 5G,
                    Galaxy S22, Galaxy S22+, Galaxy S22 Ultra, Galaxy Note 20
                    Ultra 5G, Galaxy Note 20, Galaxy Fold, Galaxy Z Fold2 5G,
                    Galaxy Z Fold3 5G, Galaxy Z Flip, Galaxy Z Flip 5G, Galaxy Z
                    Flip3 5G)
                  </span>
                  <span className='pl-6 my-1 text-sm text-neutral-700'>
                    * 그 밖의 다른 iOS, 안드로이드 기기는 *#06#
                    (별표-샾-0-6-샾)번호를 눌러서 EID 정보가 나오면 가능합니다.
                    출시국가 및 모델에 따라 다를 수 있으니 자세한 사항은
                    제조사에 먼저 문의 바랍니다
                  </span> */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return isLoading || !pickedPlanState.slug ? (
    <Loader />
  ) : (
    <div className='nc-EsimPlandDetail'>
      {/* MAIN */}
      <main className=' relative z-10 mt-11 flex flex-col lg:flex-row pb-20'>
        {/* CONTENT */}
        <div className='w-full lg:w-3/5 xl:w-2/3 space-y-2 lg:pr-10'>
          {isLoading ? (
            <Loader counts={7} />
          ) : (
            <>
              <div className='listingSection__wrap !space-y-2'>
                {/* 헤더 제목 및 간단한 설명 */}
                {/* <div className='flex items-start'>
                    {selectedPlanState?.unlimiitedCapacity && (
                      <Badge name='데이터 무제한' className='mr-1' />
                    )}
                    {selectedPlanState?.canCallOrText === 'call_only' && (
                      <Badge color='yellow' name='현지 전화가능' />
                    )}
                    {selectedPlanState?.canCallOrText === 'text_only' && (
                      <Badge color='yellow' name='현지 문자가능' />
                    )}
                    {selectedPlanState?.isLocalEsim && (
                      <Badge color='red' name='로컬심' />
                    )}
                    {selectedPlanState?.unlimiitedCapacity.includes('mbps') && (
                      <Badge color='red' name='초고속 무제한' />
                    )}
                  </div> */}

                <div className='text-xl sm:text-2xl lg:text-3xl font-semibold ml-2'>
                  <div className='text-xs font-light mb-2 text-neutral-500'>
                    플랜명
                  </div>
                  {pickedPlanState.name_kr}
                  <div className='text-xs font-light mb-2 text-neutral-500'>
                    {pickedPlanState.name}
                  </div>
                </div>
                <div className='flex items-center'>
                  {/* <span className='ml-2.5 text-neutral-500 dark:text-neutral-400'>
                    </span> */}
                </div>

                <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

                <div className='grid grid-cols-1 md:grid-cols-2 gap-2 text-sm text-neutral-700 dark:text-neutral-300'>
                  <div className='flex items-center space-x-1 '>
                    <i className='las la-broadcast-tower text-2xl text-primary-600'></i>
                    <span className=' md:inline-block text-neutral-500'>
                      통신사:
                    </span>
                    <span className='font-medium text-base'>
                      {pickedPlanState.locationNetworkList[0].operatorList
                        .length > 1
                        ? pickedPlanState.locationNetworkList[0].operatorList
                            .map((d) => d.operatorName)
                            .join(',') // Use join to create a string with commas
                        : pickedPlanState.locationNetworkList[0].operatorList[0]
                            .operatorName}
                    </span>
                  </div>
                  <div className='flex items-center space-x-1'>
                    <i className='las la-calendar text-2xl text-primary-600'></i>
                    <span className=' md:block text-neutral-500'>
                      사용기간:
                    </span>
                    <span className='font-medium text-base'>
                      {pickedPlanState.duration}일
                    </span>
                  </div>
                  <div className='flex items-center space-x-1'>
                    <i className=' las la-rocket text-2xl text-primary-600'></i>
                    <span className=' md:block text-neutral-500'>속도:</span>
                    <span className='font-medium text-base'>
                      {
                        pickedPlanState.locationNetworkList[0].operatorList[0]
                          .networkType
                      }
                    </span>
                  </div>
                  <div className='flex items-center space-x-1'>
                    <i className=' las la-qrcode text-2xl text-primary-600'></i>
                    <span className=' md:block text-neutral-500'>
                      eSIM 유효기간:
                    </span>
                    <span className='font-medium text-base'>
                      {pickedPlanState.unusedValidTime}일
                    </span>
                  </div>

                  <div className='flex items-center space-x-1'>
                    <i className=' las la-phone-alt text-2xl text-primary-600'></i>

                    <span className=' md:block text-neutral-500'>
                      현지 전화/문자:
                    </span>
                    <span className='font-medium text-base'>
                      {'데이터 전용'}
                    </span>
                  </div>

                  <div className='flex items-center space-x-1'>
                    <i className=' las la-wifi text-2xl text-primary-600'></i>
                    <span className=' md:block text-neutral-500'>핫스팟:</span>
                    <span className='font-medium text-base'>{'가능'}</span>
                  </div>
                </div>
              </div>{' '}
              <div className='listingSection__wrap'>
                <div>
                  <h2 className='text-2xl font-semibold'>
                    {' '}
                    ✅ 구매하시기 전에
                  </h2>
                  <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
                    꼭 읽어주세요
                  </span>
                </div>
                {/* 6 */}
                <div className='grid grid-cols-1 gap-3 text-neutral-700 dark:text-neutral-300'>
                  {notes
                    .filter((_, i) => i < 12)
                    .map((item) => (
                      <div
                        key={item.name}
                        className='flex items-center space-x-3'
                      >
                        <i className='las la-check-circle text-2xl text-primary-600'></i>
                        <span className='text-sm sm:text-base text-neutral-500'>
                          {item.name}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div className='listingSection__wrap'>
                {/* HEADING */}
                <h2 className='text-2xl font-semibold'>❗eSIM 사용 유의사항</h2>

                {/* CONTENT */}
                <div>
                  <h4 className='text-lg font-semibold'>eSIM 지원 기기</h4>
                  <div className='prose sm:prose'>
                    <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                      <li>컨트리락이 해제된 기기인지 확인해주세요</li>
                      <li>eSIM 서비스가 가능한 기기인지 확인해주세요</li>
                      <li>
                        같은 제조사, 모델이라도 출시 국가에 따라 eSIM 지원이
                        안될 수 있습니다
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <ButtonSecondary
                    onClick={openModalAmenities}
                    className='border-primary-400'
                  >
                    eSIM 지원 단말기 확인 하기
                  </ButtonSecondary>
                </div>
                {renderEsimSupportedDevice()}

                {/* CONTENT */}
                <div>
                  <h4 className='text-lg font-semibold'>사용기간</h4>

                  {/* 데이터 종량제 1: 처음 접속부터 시간이 계산 */}
                  {/* 데일리 플렌제 2: HK기준 */}

                  {pickedPlanState.dataType === 1 ? (
                    <>
                      <div className='prose sm:prose'>
                        <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                          <li>
                            사용일은 처음 현지국가 네트워크 접속부터 시작됩니다
                          </li>
                          <li>예시) 데이터 종량제 10일 사용일수</li>
                        </ul>
                      </div>
                      <div className='mt-3 sm:ml-8 sm:mr-28 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base mx-auto'>
                        <div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
                          <span>현지 네트워크 접속</span>
                          <span>3월1일 10am (현지시각)</span>
                        </div>
                        <div className='flex space-x-10 justify-between p-3'>
                          <span>데이터 서비스 종료</span>
                          <span>3월11일 10am (현지시각)</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {' '}
                      <div className='prose sm:prose'>
                        <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                          <li>
                            사용일은 한국시간 새벽 1시 기준이며, 데일리 플랜의
                            경우 데이터가 새로 제공됩니다
                          </li>
                          <li>예시) 데일리 플랜형 10일 eSIM 사용일수</li>
                        </ul>
                      </div>
                      <div className='mt-3 sm:ml-8 sm:mr-28 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base mx-auto'>
                        <div className='flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg'>
                          <span>현지 네트워크 접속</span>
                          <span>3월1일 10am (한국시간)</span>
                        </div>
                        <div className='flex space-x-10 justify-between p-3'>
                          <span>데이터 서비스 종료</span>
                          <span>3월11일 1am (한국시간)</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* CONTENT */}
                <div>
                  <h4 className='text-lg font-semibold'>
                    네트워크 및 통신속도
                  </h4>
                  <div className='prose sm:prose'>
                    <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                      <li>
                        현지 통신사의 사정에 따라서 LTE가 아닌 3G로 연결될 수
                        있습니다
                      </li>
                      <li>
                        해당국가에 따라 간혹 서비스가 안되는 앱이 있을 수
                        있습니다
                      </li>
                    </ul>
                  </div>
                </div>

                {/* CONTENT */}
                <div>
                  <h4 className='text-lg font-semibold'>환불</h4>
                  <div className='prose sm:prose'>
                    <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                      <li>구매 후 14일 전까지 QR코드 스캔이 되지 않았거나</li>
                      <li>제품에 문제가 있을 경우 환불 가능합니다</li>
                    </ul>
                  </div>
                  <div className='mt-4 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                    아래의 경우 환불이 불가능합니다.
                  </div>
                  <div className='prose sm:prose'>
                    <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                      <li>
                        현지국가 통신사의 네트워크 사정에 의한 서비스 제한
                      </li>
                      <li>
                        이미 QR코드가 스캔되어 eSIM이 활성화 되어 있는 경우
                      </li>
                      <li>데이터가 이미 사용된 경우</li>
                    </ul>
                  </div>
                </div>
              </div>{' '}
            </>
          )}
        </div>

        {/* 가격정보 */}
        <div className='hidden lg:block flex-grow mt-14 lg:mt-0'>
          {isLoading ? (
            <Loader counts={2} />
          ) : (
            <>
              <div className='sticky top-28'>
                <div className='listingSectionSidebar__wrap shadow-xl'>
                  {/* SUM */}
                  <div className='flex flex-col space-y-2'>
                    <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
                      <span className='text-2xl'>
                        {pickedPlanState.name_kr}
                        <div className='font-light text-xs text-neutral-500'>
                          {pickedPlanState.name}
                        </div>
                      </span>
                    </div>
                    <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
                      <span className='text-neutral-500'>데이터 종량제</span>
                    </div>
                  </div>
                  {/* PRICE */}
                  <div className='flex justify-between'>
                    <span className='text-3xl font-semibold ml-auto'>
                      {(pickedPlanState.consumerPrice / 10000).toLocaleString(
                        'en-US',
                        {
                          // style: 'currency',
                          // currency: 'USD',
                          minimumFractionDigits: 2,
                          // maximumFractionDigits: 2,
                        },
                      )}{' '}
                      <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                        {currencyState}
                      </span>
                    </span>
                  </div>

                  {/* FORM */}
                  {/* <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput className="flex-1 z-[11]" />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" />
        </form> */}

                  {/* SUBMIT */}
                  <ButtonPrimary href={`/checkout/${location}/${slug}`}>
                    <span className='text-lg font-semibold'>구매하기</span>
                  </ButtonPrimary>
                </div>
              </div>
            </>
          )}
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      <NewMobileFooterSticky plan={pickedPlanState} />
    </div>
  );
};

export default function () {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
