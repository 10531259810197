/**
 * HONEY ESIM 결제 완료 페이지
 */

import { db, doc, getDoc } from 'api/firebase';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'shared/Loader/Loader';
import { OrderData } from '../../type/interface';

import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

const RequestEsim = () => {
  const { orderID } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState<OrderData | null>(null);
  const [alreadyRequested, setAlreadyRequested] = useState(false); // 벤더 rqst id 존재
  const [justRequested, setJustRequested] = useState(false); // 방금 발권 버튼 누름 => DB 업데이트 with 벤더 rqst id
  const [esimInfoExists, setEsimInfoExists] = useState(false); // esim qr코드 주소 존재
  const [orderCancelled, setOrderCancelled] = useState(false); // 취소 및 환불된 오더

  const [isRequesting, setIsRequesting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const requestQRcode = async () => {
    try {
      setIsRequesting(true);
      // request QR code and store into DB
      await axios.post(
        'https://us-central1-esim-connect.cloudfunctions.net/requestQRcodeOnUserClick',
        { orderID },
      );

      setJustRequested(true);
    } catch (e) {
      console.log('ERR@RequestEsim', e);
    } finally {
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    const fetchOrder = async () => {
      setIsLoading(true);
      try {
        const orderDocRef = doc(db, 'ORDERS', orderID as string);
        const orderDoc = await getDoc(orderDocRef);

        // DOC EXISTS?
        if (orderDoc.exists()) {
          const orderData = orderDoc.data();
          setOrder(orderData as any);

          // 3. DOC.DATA()에서 이미 공급사 request_id 었는지 확인
          const {
            esimAccessOrderNo,
            is_esim_sent,
            is_cancelled_or_refunded,
            esimStatus,
          } = orderData;

          // 페이플, 네이버 등 cancel 된 부분 있는지 확인!
          if (is_cancelled_or_refunded || esimStatus === 'CANCEL') {
            setOrderCancelled(true);
          } else if (is_esim_sent || esimStatus) {
            setAlreadyRequested(true);
            setEsimInfoExists(true);
            // 오더는 되었지만 발권이 안되었을 떄
          } else if (esimAccessOrderNo && (!is_esim_sent || !esimStatus)) {
            setAlreadyRequested(true);
            setEsimInfoExists(false);
          }
        } else {
          // DOC 이 없으면
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrder();
  }, [orderID, navigate, setOrder]);

  /////////////////////////////////////////////////////////////////////
  //////////////////              UI             //////////////////////
  /////////////////////////////////////////////////////////////////////

  const renderPage = () => {
    if (isLoading) {
      return (
        <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
          <Loader />
        </div>
      );
    } else if (orderCancelled) {
      return renderAlreadyIssued(
        '취소된 오더입니다',
        '주문하신 건은 이미 취소되었습니다. 감사합니다',
      );
    } else if (alreadyRequested && esimInfoExists) {
      return renderAlreadyIssued(
        '이미 QR코드가 발행 되었습니다',
        'eSIM 정보가 알림톡, 이메일로 발송되었습니다',
      );
    } else if (alreadyRequested && !esimInfoExists) {
      return renderAlreadyIssued(
        '발권이 이미 요청되었습니다',
        '발권 요청 후 5분 이내로 QR코드가 오지 않았다면 고객센터로 문의 주세요',
      );
    } else if (justRequested) {
      return renderAlreadyIssued(
        '발권 요청이 완료되었습니다',
        '5분 안으로 QR코드가 이메일 및 알림톡으로 전송됩니다',
      );
    } else {
      return renderRequestEsim();
    }
  };

  ////////////////////////////////////////////////////////////////////
  //////////////////            QR REQUEST UI          //////////////
  ///////////////////////////////////////////////////////////////////

  const renderRequestEsim = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <h2 className='text-3xl lg:text-4xl font-semibold mt-8'>
          {'eSIM 발권 페이지 🛩️'}
        </h2>

        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>

        <div className='space-y-6'>
          <h3 className='text-2xl font-semibold'>주문하신 상품</h3>
          <div className='flex flex-col sm:flex-row sm:items-center border rounded-2xl border-primary-400'>
            <div className='pt-5 pb-5 px-5 space-y-3'>
              <div>
                <span className='text-base sm:text-lg font-medium mt-1 block'>
                  {order?.plan_name_kr}
                </span>
              </div>
              <span className='block text-sm text-neutral-500 dark:text-neutral-400'>
                {order?.plan_detail?.unlimiitedCapacity
                  ? `데이터 소진 후 ${order.plan_detail.unlimiitedCapacity} 무제한`
                  : ``}
              </span>
            </div>
          </div>
        </div>

        <div className='space-y-6 pt-4'>
          <h3 className='text-2xl font-semibold'>주문 상세 정보</h3>
          <div className='flex flex-col space-y-4'>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>주문번호</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {orderID}
              </span>
            </div>
            {/* Add more order details here as needed */}
          </div>
        </div>
        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>

        <div className='flex justify-center pt-4'>
          <ButtonPrimary
            onClick={() => requestQRcode()}
            loading={isRequesting}
            disabled={isRequesting}
          >
            발권하기
          </ButtonPrimary>
          <div className='mx-1'></div>
          <ButtonSecondary href='/'>홈으로 가기</ButtonSecondary>
        </div>
      </div>
    );
  };

  /////////////////////////////////////////////////////////////////////
  //////////////////           이미 발권 혹은 취소 UI         //////////////
  /////////////////////////////////////////////////////////////////////

  const renderAlreadyIssued = (title: string, message: string) => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <h2 className='text-3xl lg:text-4xl font-semibold mt-8'>
          {title}
          <div className='text-base text-neutral-500 font-light mt-4'>
            {message}
            <div>
              더 자세한 사항은 오더 ID와 함께
              <a href='mailto:hello@honey-esim.com'>
                <span className='text-primary-500'> hello@honey-esim.com</span>
              </a>{' '}
              으로 문의주시면 감사하겠습니다
            </div>
          </div>
        </h2>

        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>

        <div className='space-y-6'>
          <h3 className='text-2xl font-semibold'>주문하신 상품</h3>
          <div className='flex flex-col sm:flex-row sm:items-center border rounded-2xl border-primary-400'>
            <div className='pt-5 pb-5 px-5 space-y-3'>
              <div>
                <span className='text-base sm:text-lg font-medium mt-1 block'>
                  {order?.plan_name_kr}
                </span>
              </div>
              <span className='block text-sm text-neutral-500 dark:text-neutral-400'>
                {order?.plan_detail?.unlimiitedCapacity
                  ? `데이터 소진 후 ${order.plan_detail.unlimiitedCapacity} 무제한`
                  : ``}
              </span>
            </div>
          </div>
        </div>

        <div className='space-y-6 pt-4'>
          <h3 className='text-2xl font-semibold'>주문 상세 정보</h3>
          <div className='flex flex-col space-y-4'>
            <div className='flex text-neutral-6000 dark:text-neutral-300'>
              <span className='flex-[3]'>오더 ID</span>
              <span className='flex-[7] font-light dark:text-neutral-100'>
                {orderID}
              </span>
            </div>
            {/* Add more order details here as needed */}
          </div>
        </div>
        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>

        <div className='flex justify-center pt-4'>
          <ButtonPrimary href='/locations'>최저가 eSIM 상품 보기</ButtonPrimary>
          <div className='mx-1'></div>
          <ButtonSecondary href='/'>홈으로 가기</ButtonSecondary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage`} data-nc-id='PayPage'>
      <main className='container mt-11 mb-24 lg:mb-32 '>
        <div className='max-w-4xl mx-auto'>{renderPage()}</div>
      </main>
    </div>
  );
};

export default RequestEsim;
