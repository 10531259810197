// ReviewPage.tsx

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import {
  addDoc,
  collection,
  db,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  Timestamp,
} from 'api/firebase';
import CommentListingV2 from 'components/CommentListing/CommentListingV2';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

export interface ReviewPageProps {
  className?: string;
}

export interface Review {
  rating: number;
  comment: string;
  createdAt: Date;
  orderNo: string;
  custName: string;
  date: string;
  plan: string;
}

const ReviewPage: FC<ReviewPageProps> = ({ className }) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null,
  );
  const [loading, setLoading] = useState(false); // Loading state
  const [hasMoreReviews, setHasMoreReviews] = useState(true); // Track if there are more reviews to fetch
  const [canWriteReview, setCanWriteReview] = useState(false);
  const [newRating, setNewRating] = useState(5);
  const [newReview, setNewReview] = useState('');
  const [orderInfo, setOrderInfo] = useState<any>(null);
  const location = useLocation();

  const orderNo = new URLSearchParams(location.search).get('orderno');

  const FETCH_DOCS_NUM = 5;

  useEffect(() => {
    if (orderNo) {
      checkOrderEligibility();
    }
    fetchReviews();
  }, [orderNo]);

  // 1. Check if the user is eligible to write a review
  const checkOrderEligibility = async () => {
    if (!orderNo) return;

    const orderRef = doc(db, 'ORDERS_V2', orderNo);
    const orderSnap = await getDoc(orderRef);

    if (orderSnap.exists()) {
      setCanWriteReview(true);
      const docData = orderSnap.data();
      console.log('docData', docData);

      const firestoreTimestamp: Timestamp =
        docData.createdAt; /* your Firestore timestamp */
      const date = firestoreTimestamp.toDate(); // Convert to JavaScript Date object
      const formattedDate = dayjs(date).format('MMMM YYYY'); // "August 2024" format

      const orderData = {
        date: formattedDate,
        plan: docData.orderDetailList[0].plan.name,
        orderNo: docData.orderNo,
        custName: `${docData.orderPaymentInfo.ppl_first_name
          .charAt(0)
          .toUpperCase()}. ${docData.orderPaymentInfo.ppl_last_name}`,
      };

      setOrderInfo(orderData);
    }
  };

  // 2. Fetch reviews with pagination (10 at a time)
  const fetchReviews = async () => {
    const reviewsQuery = query(
      collection(db, 'REVIEWS_V2'),
      orderBy('createdAt', 'desc'),
      limit(FETCH_DOCS_NUM),
      ...(lastVisible ? [startAfter(lastVisible)] : []), // Start after last fetched doc if exists
    );

    const reviewDocs = await getDocs(reviewsQuery);
    const fetchedReviews = reviewDocs.docs.map((doc) => ({
      ...doc.data(),
    })) as Review[];

    setReviews((prevReviews) => [...prevReviews, ...fetchedReviews]);
    setLastVisible(reviewDocs.docs[reviewDocs.docs.length - 1]); // Update lastVisible for pagination

    // If fewer than the limit of reviews are returned, stop further fetching
    if (fetchedReviews.length < FETCH_DOCS_NUM) {
      setHasMoreReviews(false);
    }
  };

  const handleReviewSubmit = async () => {
    if (!newReview.trim()) return;

    const newReviewdoc: Review = {
      rating: newRating,
      comment: newReview,
      createdAt: new Date(),
      orderNo: orderInfo.orderNo,
      custName: orderInfo.custName,
      date: orderInfo.date,
      plan: orderInfo.plan,
    };

    await addDoc(collection(db, 'REVIEWS_V2'), newReviewdoc);

    setNewReview('');
    setReviews((prevReviews) => [newReviewdoc, ...prevReviews]);
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-6 sm:py-10 lg:pb-10 ${className}`}
    >
      <div className='listingSection__wrap'>
        <div className='text-2xl font-semibold'>
          구매 리뷰{' '}
          <span className='text-sm font-light text-neutral-800'>
            ({reviews.length})
          </span>
          <div className='text-sm text-neutral-700 font-light mt-2'>
            직접 구매하신 고객분들의 리뷰 입니다
          </div>
        </div>

        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>

        {canWriteReview && (
          <div className='space-y-5'>
            <FiveStartIconForRate
              iconClass='w-6 h-6'
              className='space-x-0.5'
              rating={newRating}
              setRating={setNewRating}
            />
            <div className='relative pr-16'>
              <textarea
                maxLength={300}
                value={newReview}
                onChange={(e) => setNewReview(e.target.value)}
                className='h-32 rounded-xl border border-primary-400'
                placeholder='여기에 자세한 후기를 남겨주세요. 감사합니다'
              />
              <ButtonCircle
                className='absolute right-2 top-1/2 transform -translate-y-1/2'
                size='w-12 h-12'
                onClick={handleReviewSubmit}
              >
                <ArrowRightIcon className='w-5 h-5' />
              </ButtonCircle>
            </div>
          </div>
        )}

        <div className='divide-y divide-neutral-100 dark:divide-neutral-800'>
          {reviews.map((review, i) => (
            <CommentListingV2
              key={i}
              data={{
                ...review,
              }}
              className='bg-gray-50 rounded-lg p-4 mb-1'
            />
          ))}
          <div className='pt-8'>
            <ButtonPrimary
              onClick={fetchReviews}
              loading={loading}
              disabled={!hasMoreReviews}
            >
              리뷰 더 보기
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPage;
