/**
 * HONEY ESIM - 지원기기
 */
import { Dialog, Transition } from '@headlessui/react';
import { db, doc, getDoc, setDoc } from 'api/firebase';
import { FC, Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import { Plan, Product } from 'type/interface';

import INT from 'images/INT.png';
import Interac1 from 'images/Interac1.png';
import Interac2 from 'images/Interac2.png';

import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

import { useAtom } from 'jotai';
import NcImage from 'shared/NcImage/NcImage';
import { currencyAtom } from 'store/atoms/currency';
import { productAtom, selectedPlanAtom } from 'store/atoms/product';
import { useCurrencyConversion } from 'utils/currencyConversion';
import CommonLayout from '../PageAddListing1/CommonLayout';

import moment from 'moment-timezone';

import { generateOrderID } from 'helpers/generateOrderID';

export interface SupportedDevicesProps {}

const SupportedDevices: FC<SupportedDevicesProps> = () => {
  const [currencyState, setCurrencyState] = useAtom(currencyAtom);
  const [productState, setProductState] = useAtom(productAtom);
  const [selectedPlanState, setSelectedPlanState] = useAtom(selectedPlanAtom);
  const { productName, planId } = useParams();
  let [searchParams] = useSearchParams();

  const { currencyConversion, exchangeRates } = useCurrencyConversion();

  const email = searchParams?.get('email');
  const phone = searchParams?.get('phone');

  const navigate = useNavigate();

  let [isOpenModal, setIsOpenModal] = useState(false);

  function closeModal() {
    setIsOpenModal(false);
  }

  function openModal() {
    setIsOpenModal(true);
  }

  const renderEtransferHowTo = () => {
    return (
      <Transition appear show={isOpenModal} as={Fragment}>
        <Dialog
          as='div'
          className='fixed inset-0 z-50 overflow-y-auto'
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block py-8 h-screen w-full max-w-4xl'>
                <div className='inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <h3
                      className='text-lg font-medium leading-6 text-gray-900'
                      id='headlessui-dialog-title-70'
                    >
                      e-Transfer 입금 방법
                    </h3>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModal} />
                    </span>
                  </div>
                  <div className='px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200'>
                    <CommonLayout>
                      <div className='grid grid-cols-1 gap-6 text-neutral-700 dark:text-neutral-300'>
                        <div className='flex items-center space-x-3'>
                          {/* <i className='las la-check-circle text-2xl text-primary-600'></i> */}
                        </div>

                        <h4 className='text-lg font-semibold'>
                          1. e-Transfer 입금하기
                        </h4>
                        <div className='prose sm:prose'>
                          <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                            <li>수신인 메일은 honey.esim@gmail.com 입니다</li>
                            <li>Message 란에 고객님 이메일을 기재해주세요</li>
                          </ul>
                        </div>

                        <div className='sm:flex'>
                          <NcImage
                            containerClassName='flex-1 items-center justify-center sm:w-full'
                            className='mt-2'
                            width={450}
                            src={Interac1}
                          />
                        </div>

                        <h4 className='text-lg font-semibold mt-10'>
                          2. 레퍼런스 넘버 복사 후 기입하기
                        </h4>
                        <div className='prose sm:prose'>
                          <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                            <li>
                              입금완료 후 Interac 입금 확인 이메일에서 레퍼런스
                              넘버 (숫자/영문) 를 복사해주세요. (아래 예시에서
                              CA2nGHHa 가 레퍼런스 넘버 입니다){' '}
                            </li>
                            <li>
                              은행에서 송금 후 보여지는 레퍼런스 넘버와는
                              다릅니다. 꼭 Interac 이메일에서 확인해주세요.
                            </li>
                          </ul>
                        </div>

                        <div className='sm:flex'>
                          <NcImage
                            containerClassName='flex-1 items-center justify-center'
                            className='mt-2'
                            src={Interac2}
                          />
                        </div>

                        <h4 className='text-lg font-semibold mt-10'>
                          3. 꿀eSIM 확인 이메일 받기
                        </h4>
                        <div className='prose sm:prose'>
                          <ul className='mt-3 text-neutral-500 dark:text-neutral-400 space-y-2 text-sm sm:text-base'>
                            <li>
                              입금이 정상적으로 이루어졌다면 비지니스 다음 날
                              까지 결제확인 이메일 및 eSIM 정보 이메일이
                              발송됩니다.
                            </li>
                            <li>
                              더 자세한 사항은 hello@honey-esim.com으로 문의
                              바랍니다
                            </li>
                          </ul>
                        </div>
                      </div>
                    </CommonLayout>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  useEffect(() => {
    setCurrencyState('CAD'); // CAD ETRANSFER
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, 'PRODUCTS', productName as string);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          // Update the product state without causing a re-render
          setProductState((prevProductState) => {
            const newData = docSnapshot.data() as Product;
            if (prevProductState !== newData) {
              sessionStorage.setItem('productState', JSON.stringify(newData));
              return newData;
            } else {
              return prevProductState;
            }
          });
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    if (productState.country.length === 0) {
      const storedProductState = sessionStorage.getItem('productState');
      // if product state exists in session storage,
      if (storedProductState) {
        const parsedStoredProductState = JSON.parse(storedProductState);
        // and product name matches the url param,
        if (parsedStoredProductState.product_name === productName) {
          // set product state
          setProductState(parsedStoredProductState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('productState');
          navigate('/404');
        }
      } else {
        // If product state is not found in session storage, fetch it from Firestore
        fetchData();
      }
    }
  }, [productName, navigate, productState, setProductState]);

  useEffect(() => {
    // if product state exists but not selectedPlanstate
    if (productState.country.length && !selectedPlanState.id) {
      // first check session storage
      const storedSelectedPlanState =
        sessionStorage.getItem('selectedPlanState');
      // if storedSelectedPlanState exists
      if (storedSelectedPlanState) {
        const parsedStoredSelectedPlanState = JSON.parse(
          storedSelectedPlanState,
        );
        // check if id matches
        if (parsedStoredSelectedPlanState.id === planId) {
          // then set selected plan state
          setSelectedPlanState(parsedStoredSelectedPlanState);
        } else {
          // otherwise remove the session storage and redirect to 404
          sessionStorage.removeItem('selectedPlanState');
          navigate('/404');
        }
      } else {
        // if not in session storage, find it from product state
        const plan = productState.plans.find((d) => d.id === planId);
        if (!plan) {
          navigate('/404');
        }
        setSelectedPlanState(plan as Plan);
        sessionStorage.setItem('selectedPlanState', JSON.stringify(plan));
      }
    }
  }, [setSelectedPlanState, selectedPlanState, productState, navigate, planId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function storeOrderData(d: any) {
    try {
      const orderRef = doc(db, 'ORDERS', d.order_id);
      await setDoc(orderRef, d, { merge: true });
      return d.e_transfer_ref;
    } catch (error) {
      console.error('Error adding document: ', error);
      throw error;
    }
  }

  const onSubmit = async ({ name, reference }: any) => {
    // store into DB

    const orderData = {
      isEtransfer: true,
      order_id: generateOrderID(productState, selectedPlanState),
      last_updated: new Date(),
      created: moment.utc().toDate(),
      created_ts_kr: moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'),
      created_ts_mt: moment()
        .tz('America/Edmonton')
        .format('YYYY-MM-DD HH:mm:ss'),
      country: productState.country,
      product_name: productState.product_name,
      plan_id: selectedPlanState.id,

      e_transfer_validated: false,
      email,
      phone,
      e_transfer_full_name: name.trim().toUpperCase(),
      e_transfer_ref: reference.trim().toUpperCase(),
      e_transfer_amount: currencyConversion(
        selectedPlanState.price as number,
        currencyState,
      ),
      e_transfer_currency: currencyState,

      plan_name_en: selectedPlanState.v_planDescription,
      plan_name_kr: selectedPlanState.name_kr,
      discount_applied: selectedPlanState.discount_applied || null,
      discount_code: selectedPlanState.discount_code || null,

      productStateDetail: {
        carrier: selectedPlanState.carrier,
        canCallOrText: selectedPlanState.canCallOrText,
        canActivateBeforeDeparture:
          selectedPlanState.canActivateBeforeDeparture,
        eSimProvider: selectedPlanState.eSimProvider,
      },

      cost: selectedPlanState.cost, // original cost in KRW
      price: selectedPlanState.price, // discounted price or original price in KRW
      margin:
        (selectedPlanState.price as number) -
        (selectedPlanState.cost as number), // discounted price or original price - cost

      // v_rqst_id: rqstQRcodeData.activationReqeust.id, //198249
      // v_rqst_product_name: rqstQRcodeData.activationReqeust.productname, //"Korea 500MB/day 1 day + unlimited 128kbps "
      // v_sim_no: rqstQRcodeData.sim.simno, // '8982403262109383614F'
      // v_sim_reg_date: rqstQRcodeData.sim.regdate, // "2024-03-26T21:09:38.347"
    };

    // Get a reference to the "ORDERS" collection
    await storeOrderData(orderData);
    navigate(`/pay-done-etransfer/${productName}/${planId}`);
  };

  return (
    <CommonLayout>
      <div className='p-4 md:p-10'>
        {/* HEADING */}
        <div className='flex items-center justify-center mb-4'>
          <span className='text-4xl font-semibold'>e-Transfer 결제</span>
          <NcImage
            containerClassName='flex-1 items-center justify-center sm:w-full'
            className='mt-2'
            src={INT}
            width={100}
          />
        </div>
        <div className='sm:text-base mb-6'>
          {currencyState}{' '}
          {currencyConversion(selectedPlanState.price as number, currencyState)}
          를 honey.esim@gmail.com 으로 보내신 후 아래 폼을 작성해주세요
        </div>

        {/* BUTTON */}
        <div className='mb-10'>
          <ButtonSecondary onClick={openModal} className='border-primary-400'>
            e-Transfer 결제 방법 확인하기
          </ButtonSecondary>
        </div>
        {renderEtransferHowTo()}
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* REF INPUT */}
          <div className='mb-4'>
            <label>
              <span className='text-lg'>레퍼런스 넘버</span>
            </label>
            <span className='ml-2 text-neutral-500 text-xs'>
              e-Transfer 입금 후 이메일로 받은 레퍼런스 넘버
            </span>
            <input
              {...register('reference', { required: true })}
              placeholder='예시) aAbBcCdD'
              type='text'
              className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
            />
            {errors.reference && (
              <span className='text-red-500 text-sm'>
                레퍼런스 넘버를 입력해주세요.
              </span>
            )}
          </div>
          {/* Name INPUT */}
          <div className='mb-4'>
            <label>
              <span className='text-lg'>영문 성함</span>
            </label>
            <span className='ml-2 text-neutral-500 text-xs'>
              e-Transfer에 등록된 고객님 영문 성함
            </span>
            <input
              {...register('name', { required: true })}
              placeholder='예시) Gildong Hong'
              type='text'
              className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
            />
            {errors.name && (
              <span className='text-red-500 text-sm'>
                영문 성함을 입력해주세요.
              </span>
            )}
          </div>
          <div className='mb-10 ml-auto'>
            <ButtonPrimary type='submit'>e-Transfer 입금 완료</ButtonPrimary>{' '}
          </div>
        </form>
      </div>
    </CommonLayout>
  );
};

export default SupportedDevices;
